(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/drawer/assets/javascripts/drawer.js') >= 0) return;  svs.modules.push('/components/lb-ui/drawer/assets/javascripts/drawer.js');

'use strict';

const _excluded = ["children", "className", "expandableAreaHeight", "onClickOverlay", "onSwipeClose", "onSwipeOpen", "isOpen", "isOverlayDisabled", "isSwipeEnabled", "direction", "style"];
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], -1 === t.indexOf(o) && {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (-1 !== e.indexOf(n)) continue; t[n] = r[n]; } return t; }
const {
  useRef
} = React;
const THRESHOLD_CLOSE_IF_OPEN = 0.1;
const THRESHOLD_OPEN_IF_CLOSED = 0.9;
const THRESHOLD_FAST_SWIPE = 0.04;
const useSwipe = _ref => {
  let {
    expandableAreaHeight,
    isOpen,
    onSwipeClose,
    onSwipeOpen
  } = _ref;
  const drawerElementRef = useRef(null);
  const swiping = useRef(false);
  const startClientY = useRef(0);
  const stopClientY = useRef(0);
  const windowHeight = useRef(0);
  const touchStartTime = useRef(0);
  const handleTouchStart = event => {
    if (event !== null && event !== void 0 && event.targetTouches[0]) {
      const {
        clientY
      } = event.targetTouches[0];
      startClientY.current = clientY;
      windowHeight.current = window.innerHeight;
      touchStartTime.current = performance.now();
    }
  };
  const handleTouchMove = event => {
    event === null || event === void 0 || event.stopPropagation();
    if (event !== null && event !== void 0 && event.targetTouches[0]) {
      const {
        clientY
      } = event.targetTouches[0];
      const isSwipingDown = clientY > startClientY.current;
      const isSwipingUp = clientY < startClientY.current;
      swiping.current = true;
      const swipingUpPosition = windowHeight.current - expandableAreaHeight - (startClientY.current - clientY);
      if (isOpen && isSwipingDown) {
        const position = Math.min(clientY - startClientY.current, windowHeight.current - expandableAreaHeight);
        requestAnimationFrame(() => {
          drawerElementRef.current.style.transform = "translate3d(0, ".concat(position, "px, 0)");
        });
      } else if (!isOpen && isSwipingUp && swipingUpPosition >= 0) {
        const position = Math.min(swipingUpPosition, windowHeight.current - expandableAreaHeight);
        requestAnimationFrame(() => {
          drawerElementRef.current.style.transform = "translate3d(0, ".concat(position, "px, 0)");
        });
      }
      stopClientY.current = clientY;
    }
  };
  const handleTouchEnd = () => {
    const currentDrawerPosition = drawerElementRef.current.getBoundingClientRect().y;
    if (swiping.current) {
      requestAnimationFrame(() => {
        drawerElementRef.current.style.transform = '';
      });
      swiping.current = false;
      const touchEndTime = performance.now();
      const swipeDuration = touchEndTime - touchStartTime.current;
      const swipeDistance = Math.abs(stopClientY.current - startClientY.current);
      const isSwipingFast = swipeDuration < 200 && swipeDistance >= windowHeight.current * THRESHOLD_FAST_SWIPE;
      const swipingUpPosition = windowHeight.current - expandableAreaHeight - (startClientY.current - stopClientY.current);
      const isSwipingUpFast = isSwipingFast && stopClientY.current < startClientY.current && swipingUpPosition >= 0;
      const isSwipingDownFast = isSwipingFast && stopClientY.current > startClientY.current;
      if (isOpen) {
        if (currentDrawerPosition >= windowHeight.current * THRESHOLD_CLOSE_IF_OPEN || isSwipingDownFast) {
          onSwipeClose();
        } else {
          onSwipeOpen();
        }
      } else if (currentDrawerPosition <= (windowHeight.current - expandableAreaHeight) * THRESHOLD_OPEN_IF_CLOSED || isSwipingUpFast) {
        onSwipeOpen();
      } else {
        onSwipeClose();
      }
      stopClientY.current = 0;
    }
  };
  return {
    drawerElementRef,
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
    swiping: swiping.current
  };
};
const DirectionClasses = {
  bottom: 'drawer--is-bottom',
  left: 'drawer--is-left',
  right: 'drawer--is-right'
};
const Drawer = _ref2 => {
  let {
      children,
      className = '',
      expandableAreaHeight = 0,
      onClickOverlay = () => {},
      onSwipeClose = () => {},
      onSwipeOpen = () => {},
      isOpen = false,
      isOverlayDisabled = false,
      isSwipeEnabled = false,
      direction = 'left',
      style = {}
    } = _ref2,
    props = _objectWithoutProperties(_ref2, _excluded);
  const {
    drawerElementRef,
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
    swiping
  } = useSwipe({
    expandableAreaHeight,
    isOpen,
    onSwipeClose,
    onSwipeOpen
  });
  const overlayClassName = ['drawer-overlay'];
  const drawerClassName = ['lb-drawer', className, DirectionClasses[direction] || DirectionClasses.left];
  if (className) {
    drawerClassName.push(className);
    overlayClassName.push(className);
  }
  if (isOpen) {
    overlayClassName.push('drawer-overlay--is-open');
    drawerClassName.push('drawer--is-open');
  } else if (swiping) {
    overlayClassName.push('drawer-overlay--is-open');
  }
  return React.createElement(React.Fragment, null, !isOverlayDisabled && React.createElement("div", {
    className: overlayClassName.join(' '),
    "data-testid": "drawer-overlay",
    onClick: onClickOverlay
  }), React.createElement("div", _extends({
    className: drawerClassName.join(' '),
    "data-testid": "qa-drawer",
    onTouchEnd: isSwipeEnabled ? handleTouchEnd : undefined,
    onTouchMove: isSwipeEnabled ? handleTouchMove : undefined,
    onTouchStart: isSwipeEnabled ? handleTouchStart : undefined,
    ref: drawerElementRef,
    style: style
  }, props), children));
};
setGlobal('svs.components.lbUi.Drawer', Drawer);

 })(window);